body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background: linear-gradient(yellow, orange);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /* position: relative; */
}

.Container {
  background-color: black;
  max-width: 1250px;
  max-height: 1250px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 90%;
  border-radius: 20px;
  /* backdrop-filter: blur(20px); */
  /* -webkit-backdrop-filter: blur(20px); */
  font-size: 15px;
  font-weight: 500;
  color: black;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.module {
  height: calc(100vh);
  height: calc(var(--vh, 1vh) * 100);
}

.pad {
  position: absolute;
  /* border: -2px dotted red; */
  background-color: black;
  /* padding: 1rem; */
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.hidden {
  display: none !important;
}

@media screen and (pointer: coarse) and (orientation: portrait) {
  .portrait {
    display: flex;
    flex-direction: row;
    transform: rotate(90deg);
  }
}

.padButton {
  display: inline-block;
  text-align: center;
  justify-content: center;

  color: yellow;
  margin: 10px 10px;
  width: 10vh;
  height: 10vh;
  border-style: solid;
  border-image: linear-gradient(yellow 0%, orange 100%);
  border-image-slice: 1;
  background: rgba(0, 0, 0, 0);
  border-image-width: 1.5vh;
  font-size: 3vh;
}

#d-pad {
  display: inline-block;
  vertical-align: middle;
  width: 30vh;
  height: 30vh;
  position: relative;
  padding: 5vh;
}

#d-pad .padButton {
  box-sizing: border-box;
  display: inline-block;
  position: absolute;

  border-style: solid;
  border-image: linear-gradient(yellow 0%, orange 100%);
  border-image-slice: 1;
  background: rgba(0, 0, 0, 0);
  border-image-width: 1.5vh;
  left: 50%;
  top: 50%;
}

#d-pad .up {
  width: 5vh;
  height: 10vh;
  transform: translate(-50%, -150%);
}
#d-pad .down {
  width: 5vh;
  height: 10vh;
  /* transform: translate(0, 100%); */
  transform: translate(-50%, 50%);
}
#d-pad .right {
  width: 10vh;
  height: 5vh;
  /* transform: translate(-100%, 0); */
  transform: translate(50%, -50%);
}
#d-pad .left {
  width: 10vh;
  height: 5vh;
  /* transform: translate(100%, 0); */
  transform: translate(-150%, -50%);
}

#keybuttons {
  padding: 5vh;
  display: inline-block;
  width: 30vh;
  height: 30vh;
  margin: auto;
  vertical-align: middle;
}

.fullscreenButton {
  position: absolute;
  top: 10px;
  right: 10px;
}
